import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
let router = new Router({
    mode: "history",
    routes: [
        // {
        //   path: "/",
        //   props: true,
        //   component: () => import("./layout/Default"),
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },
        {
            path: "",
            name: "home",
            props: true,
            component: () =>
                import ("./views/Home/indexx"),

        },

        {
            path: "/About",
            name: "About Us",
            // props: true,
            component: () =>
                import ("./views/About/about"),

        },
        {
            path: "/viewGallery",
            name: "viewGallery",
            // props: true,
            component: () =>
                import ("./views/Gallery/viewGallery"),

        },
        {
            path: "/team",
            name: "Team",
            // props: true,
            component: () =>
                import ("./views/Team/team"),

        },
        {
            path: "/Contact",
            name: "AboutUs",
            // props: true,
            component: () =>
                import ("./views/Contact/contactView"),

        },
        {
            path: "/membership",
            name: "Membership",
            // props: true,
            component: () =>
                import ("./views/getInvolved/membership"),

        },
        {
            path: "/Volunteer",
            name: "Volunteer",
            // props: true,
            component: () =>
                import ("./views/getInvolved/volunteer"),

        },
        
        {
            path: "/Events",
            name: "Events",
            // props: true,
            component: () =>
                import ("./views/Events/events"),

        },
        {
            path: "/Webinars",
            name: "Webinars",
            // props: true,
            component: () =>
                import ("./views/Events/webinars"),

        },
        {
            path: "/WebinarsHome",
            name: "WebinarsHome",
            // props: true,
            component: () =>
                import ("./views/Events/webinarsHome"),

        },
        {
            path: "/OfflinePrograms",
            name: "OfflinePrograms",
            // props: true,
            component: () =>
                import ("./views/Events/offlineProg"),

        },
        {
            path: "/DonateUs",
            name: "DonateUs",
            // props: true,
            component: () =>
                import ("./views/Events/donateUs"),

        },
        // {
        //   path: "/PrivacyPolicy",
        //   name: "PrivacyPolicy",
        //   // props: true,
        //   component: () => import("./views/Static/PrivacyPolicy"),
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },

        {
            path: "/servererror",
            name: "servererror",
            props: true,
            component: () =>
                import ("./layout/500"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "*",
            name: "404pagenotfound",
            props: true,
            component: () =>
                import ("./layout/404"),
            meta: {
                requiresAuth: false,
            },
        },
    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
});
// router.beforeEach((to, from, next) => {
//   if (
//     to.matched.some((route) => route.meta.requiresAuth == true) &&
//     store.state.isLoggedIn == false
//   ) {
//     console.log(from);
//     next({ name: "login", params: { lastPage: from } });
//     return;
//   }
//   if (store.state.isLoggedIn == true) {
//     axios({
//       method: "POST",
//       url: "/validate/token",
//       headers: {
//         token: localStorage.getItem("token"),
//       },
//     })
//       .then((response) => {
//         if (!response.data.status) {
//           store.commit("sessionOut", true);
//           return;
//         }
//       })
//       .catch((err) => {
//         var msg = err;
//         console.log(msg);
//       });
//   }
//   if (
//     (to.name == "login" || to.name == "home") &&
//     store.state.isLoggedIn == true
//   ) {
//     next({ name: "dashboard" });
//     return;
//   }
//   next();
// });

export default router;